import React from "react";
import { graphql, Link } from "gatsby";
import DefaultLayout from "../components/layouts/DefaultLayout";
import SEO from "../components/layouts/SEO";

interface IHomePage {
  data: any;
}

const HomePage: React.FC<IHomePage> = ({ data }) => {
  return (
    <>
      <SEO
        url={data?.site?.siteMetadata?.siteUrl}
        title={"404 - Page not found"}
        desc={"Page not found"}
        ogImage={`${data?.site?.siteMetadata?.siteUrl}/assets/img/home.png`}
      />
      <DefaultLayout>
        <section className="py-[15rem] bg-gradient-to-br from-[#560e4a] to-[#170737]">
          <div className="w-[90%] text-[#fff] max-w-[1140px] mx-auto">
            <h1 className="text-[32px] text-[#fff] lg:text-[44px] font-bold">404 - Page not found</h1>
            <p>The page you were looking for might have moved or been removed.</p>
            <Link className="size-small max-w-max type-default color-white mt-6" to="/">Back to homepage</Link>
          </div>
        </section>
      </DefaultLayout>
    </>
  );
};

export const query = graphql`
  query lessons {
    site {
      siteMetadata {
        siteUrl
        title
        description
      }
    }
  }
`;

export default HomePage;
